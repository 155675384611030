import React, { useEffect } from 'react';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Estudios from './components/Estudios';
import Consultoria from './components/Consultoria';
import Nosotros from './components/Nosotros';
import { ThemeProvider } from '@mui/material/styles'; // Material-UI
import theme from './theme.js';
import { initAnalytics, logPageView } from './analytics'; // Importar funciones
import WhatsAppButton from './components/WhatsAppButton'; // Importa el componente
import EmailButton from './components/EmailButton'; // Importa el nuevo botón

function App() {
  useEffect(() => {
    initAnalytics(); // Inicializar Analytics
  }, []);

  const AnalyticsWrapper = () => {
    const location = useLocation();

    useEffect(() => {
      logPageView(location.pathname); // Rastreo de vistas de página
    }, [location]);

    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AnalyticsWrapper /> {/* Componente para rastreo */}
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/estudios" element={<Estudios />} />
            <Route path="/consultoria" element={<Consultoria />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="*" element={<div>Página no encontrada</div>} />
          </Routes>
        </div>
        <EmailButton /> {/* Botón flotante de correo */}
        <WhatsAppButton /> {/* Botón flotante de WhatsApp */}
        <footer className="footer">
          SHC - Todos los derechos reservados
        </footer>        
      </Router>
    </ThemeProvider>
  );
}

export default App;
