import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Importa useLocation
import {
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  CssBaseline,
  Button,
  IconButton,
  useMediaQuery,
  useTheme
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SchoolIcon from '@mui/icons-material/School';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import GroupIcon from '@mui/icons-material/Group';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Logo from './assets/logo.svg';

const drawerWidth = 240;

const Navbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation(); // Hook para detectar cambios de ruta

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const activeStyle = {
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
  };

  // Efecto para reiniciar el scrollbar al inicio cuando cambia la ruta
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]); // Se ejecuta cada vez que cambia la ruta

  return (
    <Box sx={{ display: 'flex', mt: -3 }}>
      <CssBaseline />

      {/* Navbar superior */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" edge="start" onClick={toggleMenu} sx={{ mr: 2 }}>
              <MenuIcon />
            </IconButton>
            <img src={Logo} alt="Logo" style={{ height: 25 }} />
          </Box>
          <Button
            component="a"
            href="https://gic.shc.com.pe"
            color="inherit"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <AccountCircleIcon sx={{ mr: 1, mb: 1 }} />
            Ingresa a tu cuenta
          </Button>
        </Toolbar>
      </AppBar>

      {/* Menú lateral */}
      <Drawer
        variant="permanent"
        sx={{
          width: isCollapsed || isMobile ? 70 : drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isCollapsed || isMobile ? 70 : drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#fff',
            height: 'calc(100vh - 60px)',
            overflowY: 'auto',
          },
        }}
      >
        <Toolbar />
        <List>
          {[
            { to: '/', text: 'Inicio', icon: <HomeIcon /> },
            { to: '/estudios', text: 'Estudios', icon: <SchoolIcon /> },
            { to: '/consultoria', text: 'Consultoría', icon: <BusinessCenterIcon /> },
            { to: '/nosotros', text: 'Nosotros', icon: <GroupIcon /> },
          ].map(({ to, text, icon }) => (
            <ListItem
              button
              key={to}
              component={NavLink}
              to={to}
              exact
              sx={{
                '&.active': activeStyle,
                padding: '3px 20px',
                marginBottom: '5px',
                color: 'black',
                textDecoration: 'none',
                fontSize: '14px',
                '&:hover': {
                  color: 'black',
                },
                '&:visited': {
                  color: 'black',
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: '#5A00D2' }}>{icon}</ListItemIcon>
              {!(isCollapsed || isMobile) && <ListItemText sx={{ pt: 1 }} primary={text} />}
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Espacio para el contenido principal */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: `${isCollapsed || isMobile ? 70 : drawerWidth}px`,
          marginTop: '0px',
        }}
      >
        <Toolbar />
        {/* Aquí va el contenido */}
      </Box>
    </Box>
  );
};

export default Navbar;
