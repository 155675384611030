import React from 'react';
import { Box, Typography, Container } from '@mui/material';

function Consultoria() {
  return (
    <Container sx={{ padding: '20px', lineHeight: '1.6' }}>
      {/* Encabezado principal */}
      <Typography variant="h4" align="center" gutterBottom>
      Consultoría en Compensaciones
      </Typography>      
      <Typography variant="body1" paragraph>
        Con la base de nuestros estudios y la experiencia de nuestros consultores, hemos diseñado un completo programa de consultorías que nos permiten ayudar a nuestros clientes en la implementación de sus políticas de compensaciones.
      </Typography>
      <Typography variant="body1" paragraph>
        Siempre bajo nuestro sello de Seriedad, Rigurosidad y Flexibilidad, nuestras consultorías son una clara guía para definir las Estrategias de Compensaciones, como rediseñar las estructuras organizacionales, administrar planes salariales y mucho más.
      </Typography>
      <Typography variant="body1" paragraph>
        A diferencia de los estudios, son diseños particulares que dependen de la necesidad y realidad de cada organización, asimismo como de la metodología que se desee trabajar. Los estudios sirven como fuente para muchos desarrollos de consultorías.
      </Typography>

      {/* Encabezado de sección */}
      <Typography variant="body1" sx={{ my: 3 }}>
        En SHC Contamos con las siguientes consultorías, principalmente:
      </Typography>

      {/* Secciones individuales */}
      {sections.map((section, index) => (
        <Box key={index} sx={{ mb: 3 }}>
          <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
            {section.title}
          </Typography>
          <Typography variant="body1" paragraph>
            {section.description}
          </Typography>
        </Box>
      ))}
    </Container>
  );
}

// Información de las secciones
const sections = [
  {
    title: 'Diseño de Estructura Salarial',
    description:
      'Proveer a la Unidad de Compensaciones de la organización la principal herramienta para administrar los salarios fijos del personal.',
  },
  {
    title: 'Esquema de Compensación Total',
    description:
      'Asesorar al cliente en la adopción de un posicionamiento remunerativo en la compensación total y en cada uno de sus componentes.',
  },
  {
    title: 'Diseño de Sistemas de Remuneración Variable',
    description:
      'Asesorar a nuestros clientes en la definición y ejecución de su estrategia de compensación variable por medio del diseño de sistemas de remuneración que estén de acuerdo con los objetivos estratégicos, tipo de área de negocio o apoyo; estamento de cargos; costos y condiciones de mercado.',
  },
  {
    title: 'Gestión Descentralizada de Compensaciones',
    description:
      'Asesorar a nuestros clientes para empoderar a los líderes de primer nivel en la organización en temas de alto impacto relacionados a la gestión de las compensaciones.',
  },
  {
    title: 'Administración de planes salariales',
    description:
      'Proveer formación a profesionales de compensaciones para la administración de la masa salarial de la empresa. Con el objetivo de otorgar remuneraciones garantizadas de todos los empleados y ejecutivos de la empresa, basadas en la gestión de ajustes salariales por mérito/desempeño y nivelaciones de sueldos bajo el mínimo del rango salarial.',
  },
  {
    title: 'Políticas de Asignaciones Internacionales',
    description:
      'Definir una política de desplazamientos internacionales con criterios homogéneos que facilite la gestión de los supervisores y que permita operar con eficiencia para la empresa y calidad de vida para los profesionales.',
  },
  {
    title: 'Valorización de Beneficios',
    description:
      'Proveer información útil a nuestros clientes para orientar los esfuerzos de su empresa en términos de revisar, definir e implementar el conjunto de beneficios para el personal.',
  },
  {
    title: 'Análisis y evaluación de cargos',
    description:
      'Determinar la importancia relativa de los cargos en la organización y sentar las bases para la aplicación del principio de equidad interna para la gestión de salarios.',
  },
  {
    title: 'Definición Estratégica de Compensaciones',
    description:
      'Ayudar al cliente a clarificar su Estrategia de Compensaciones focalizándose en los elementos que impactan directamente en los resultados del negocio.',
  },
  {
    title: 'Análisis y diseño de Descriptores de puestos',
    description:
      'Trabajo que consiste en la revisión de las descripciones de cargos con el diagnóstico y oportunidad de mejora, correspondiente. Asimismo, en caso la compañía no tenga dicho registro, también desarrollamos las descripciones de cargos por medio de dos opciones: talleres de autodescripción o diseño de los mismos por medio de entrevistas individuales y grupales.',
  },
];

export default Consultoria;
