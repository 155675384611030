import React from 'react';
import './EmailButton.css'; // Archivo CSS compartido con WhatsApp
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Icono de correo de Material-UI

function EmailButton() {
  const handleEmailClick = () => {
    window.location.href = 'mailto:contacto@shc.com.pe';
  };

  return (
    <div className="floating-button" onClick={handleEmailClick}>
      <MailOutlineIcon fontSize="large" className="icon-email"  /> {/* Icono con estilo */} 
    </div>
  );
}

export default EmailButton;
