import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Estudios = () => {
  return (
    <Container sx={{ padding: '20px', lineHeight: '1.6' }}>
      {/* Título Principal */}
      <Typography variant="h4" align="center" gutterBottom>
        Estudios de Compensaciones
      </Typography>
      <Typography variant="body1" paragraph>
        Los estudios de compensaciones son informes estadísticos que contienen fuentes confiables y vigentes, de información salarial, de beneficios y de otros antecedentes que sirven a la gestión de Recursos Humanos. Estos informes son contratados por las compañías para tomar decisiones sobre la estructura de pago, compensaciones, mecanismos de retención e incentivos y además, para comparar su situación con respecto al mercado.
      </Typography>

      {/* Subtítulo */}
      <Typography variant="body1" sx={{ mb: '30px' }}>
        En SHC Contamos con los siguientes estudios:
      </Typography>

      {/* GIC SHC Section */}
      <Box sx={{ mb: '30px' }}>
        <Typography variant="h5" sx={{ mt: 1, mb: 1, fontWeight: 'bold' }}>
          GIC SHC
        </Typography>        
        <Typography variant="body1" paragraph>
          Olvídate de la escasez de información salarial. GIC SHC aporta las soluciones que necesitas al alcance de un clic. Ponemos a tu disposición nuestro Gestor Integral de Compensaciones (GIC), la herramienta online que facilitará una correcta toma decisiones respecto a toda la estructura organizacional de tu empresa.
        </Typography>
        <Typography variant="body1" paragraph>
          GIC es una Plataforma de Salarios que cuenta con una muestra que supera las 280 compañías. Único en su tipo en Perú, otorga una plataforma flexible y con el mismo estándar que caracterizaban los estudios de Altos Ejecutivos y Nivel General. Esta plataforma otorga la posibilidad de acceder a herramientas novedosas que le permitirán a los usuarios de recursos humanos, gestionar fuentes transversales y específicas. Con GIC SHC se podrá acceder a:
        </Typography>
        
        <List>
          {[
            'Información de salarios de altos ejecutivos y nivel general (gerentes generales hasta administrativos y operarios).',
            'Más de 9 tipos de segmentaciones posibles.',
            'Posibilidad de armar muestras específicas según las compañías de elección.',
            'Personalizar el informe o la presentación del estudio.',
            'Información de salarios agregada o descompuesta de acuerdo con los criterios que elija el usuario.',
            'Toda la data de salarios aportadas por la empresa, con la posibilidad de actualizar la información periódicamente.',
            'Un panel de control de usuarios para gestionar el uso de la herramienta.',
            'Información actualizada mensualmente.',
            'Informe Anual con análisis cualitativos.',
            'Encuestas detalladas de reajustes o particularidades.',
          ].map((item, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckCircleIcon color="secondary" /> {/* Ícono elegante y representativo */}
              </ListItemIcon>
              <ListItemText primary={item} />
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Beneficios Laborales Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Beneficios Laborales
        </Typography>
        <Typography variant="body1" paragraph>
        Estudio que contiene Información de más de 100 beneficios y que involucra antecedentes que inciden diariamente en las políticas de recursos humanos; desde las remuneraciones y pago de horas extra hasta las jornadas de trabajo flexible. En este estudio se analiza cada beneficio de acuerdo con su frecuencia de entrega, origen del otorgamiento, modalidad de entrega, requisitos y condiciones para acceder a él, valores si los hay y otros antecedentes adicionales. 
        </Typography>
        <Typography variant="body1" paragraph>        
        Este análisis se presenta segmentado según sector económico, ventas, número de trabajadores, origen de capital y presencia de sindicatos, según nivel. Para facilitar su análisis, los beneficios son clasificados en 10 familias: Bonos y Asignaciones, Educación, Flexibilidad Horaria, Eventos y Festividades, Convenios, Asistencia, Pensión, Bienes y Especies, Salud y Vida & Políticas Laborales y de Promoción. Cuenta con una muestra de más de 100 empresas.
        </Typography>
      </Box>

      {/* Job Pricing Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Job Pricing
        </Typography>
        <Typography variant="body1" paragraph>
        Análisis salarial específico sobre posiciones y empresas seleccionadas por el cliente. El JOB PRICING es una solución ajustada a una necesidad específica, por su precisión e individualización. Muchas de las respuestas se encuentran en los Estudios de Compensaciones transversales, sin embargo, existen cargos específicos y/o estratégicos de una actividad de negocio que necesitan información complementaria. Para ello, se desarrolla el servicio de JOB PRICING. Para su desarrollo, el cliente entrega a SHC un listado de empresas de interés, con las que se desea efectuar el análisis para cargos equivalentes, los que son presentados bajo la misma metodología de SHC.
        </Typography>
      </Box>

      {/* Clubes Section */}
      <Box sx={{ marginBottom: '30px' }}>
        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Clubes
        </Typography>
        <Typography variant="body1" paragraph>
        Análisis salarial específico sobre un conjunto de empresas organizadas y estructuradas para este fin. En este tipo de estudios, cofinanciados por cada una de las empresas participantes, se buscar resolver información de posiciones específicas, principalmente del core del negocio. En esta línea de productos, hemos desarrollado por décimo año consecutivo, el Estudio de Compensaciones en Áreas de Inversión, único en su tipo y que se ha consolidado en empresas financieras. 
        </Typography>
        <Typography variant="body1" paragraph>        
        En este estudio, las empresas son agrupadas en 6 áreas de negocios: AFP, Bancos Comerciales, Corredoras de Bolsa, Seguros, Family Office y Asset Management. A su vez, la información es segmentada según Ventas, Instrumento de especialización, área de negocio, responsabilidad corporativa y Porfolio Administrado, todas variables propias al mundo de inversiones. Este estudio cuenta con la participación de más de 40 compañías.
        </Typography>
      </Box>
    </Container>
  );
};

export default Estudios;
