import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Icono de Material-UI
import './WhatsAppButton.css'; // Estilos para el botón

const WhatsAppButton = () => {
  const handleClick = () => {
    window.open('https://wa.me/+56950163882', '_blank'); // Reemplaza con tu número de WhatsApp
  };

  return (
    <div className="whatsapp-button" onClick={handleClick}>
      <WhatsAppIcon fontSize="large" style={{ color: '#25D366' }} />
    </div>
  );
};

export default WhatsAppButton;
