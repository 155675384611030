import ReactGA from 'react-ga4';

const initAnalytics = () => {
  const isProduction = process.env.REACT_APP_ENV === 'production';
  const trackingId = process.env.REACT_APP_GA_TRACKING_ID;

  if (isProduction && trackingId) {
    ReactGA.initialize(trackingId); // Inicializa solo en producción
    console.log("Google Analytics habilitado en producción.");
  } else {
    console.log("Google Analytics deshabilitado en desarrollo.");
  }
};

const logPageView = (path) => {
  const isProduction = process.env.REACT_APP_ENV === 'production';

  if (isProduction) {
    ReactGA.send({ hitType: "pageview", page: path });
  }
};

export { initAnalytics, logPageView };
