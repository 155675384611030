import React from 'react';
import { Box, Grid, Card, CardContent, Typography, CardActions, Button } from '@mui/material';
import { BarChart, ViewModule, Insights } from '@mui/icons-material';


const Home = () => {
  const cards = [
    {
      title: "Estudios",
      description: "Los estudios de compensaciones son informes estadísticos que contienen fuentes confiables y vigentes, de información salarial, de beneficios y de otros antecedentes que sirven a la gestión de Recursos Humanos...",
      icon: <Insights fontSize="large" />,
      link: "/estudios",
    },
    {
      title: "Consultoría",
      description: "Con la base de nuestros estudios y la experiencia de nuestros consultores, hemos diseñado un completo programa de consultorías que nos permiten ayudar a nuestros clientes en la implementación de sus políticas de compensaciones...",
      icon: <ViewModule fontSize="large" />,
      link: "/consultoria",
    },
    {
      title: "Nosotros",
      description: "Somos una firma líder en investigación y desarrollo de estudios de compensaciones. Nos destacamos por el rigor que asignamos a dichas tareas, con una alta y exigente precisión técnica, pero a su vez, flexible para su análisis e interpretación...",
      icon: <BarChart fontSize="large" />,
      link: "/nosotros",
    }
  ];

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Bienvenido a SHC
      </Typography>
      <Grid container spacing={3} sx={{ maxWidth: "1200px" }}>
        {cards.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card variant="outlined" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "10px", color: '#5A00D2' }}>
                  {card.icon}
                  <Typography variant="h6" component="div" sx={{ marginLeft: "10px", pt: 2 }}>
                    {card.title}
                  </Typography>
                </div>
                <Typography variant="body2" color="text.secondary">
                  {card.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href={card.link} color="text.secondary">
                  IR
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

    </Box>
  );
};

export default Home;
