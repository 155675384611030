// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#f5f5f5',
    },
    secondary: {
      main: '#000',
    },
  },
  typography: {
    fontFamily: 'GothamBook',
    fontSize: 11,
    color: '#323E48',
    h2: {
      fontSize: '48px',

    },
    h3: {
        fontSize: '38px',
        fontFamily: 'GothamBlack',
        color: '#323E48',
    },
    h4: {
        fontSize: '29px',
        fontFamily: 'GothamBlack',
        color: '#323E48',
    },
    h5: {
        fontSize: '21px',
        fontFamily: 'GothamBook',
        color: '#323E48',        
    },      
  },
});

export default theme;
