import React from 'react';
import { Box, Typography, Container, List, ListItem, ListItemText } from '@mui/material';

function Nosotros() {
  return (
    <Container sx={{ padding: '20px', lineHeight: '1.6' }}>
      {/* Encabezado principal */}
      <Typography variant="h4" align="center" gutterBottom>
      Nosotros
      </Typography>    
      <Typography variant="body1" paragraph>
        Somos una firma líder en investigación y desarrollo de estudios de compensaciones. Nos destacamos por el rigor que asignamos a dichas tareas, con una alta y exigente precisión técnica, pero a su vez, flexible para su análisis e interpretación.
      </Typography>
      <Typography variant="body1" paragraph>
        Somos una empresa líder en investigación y elaboración de estudios de compensaciones en el Perú. Nos destacamos por el rigor que asignamos a dichas tareas, con un alto conocimiento de la realidad local. Nuestros servicios han sido contratados por las más prestigiosas empresas de la región, nacionales e internacionales, por organismos públicos y empresas del Estado.
      </Typography>
      <Typography
          variant="body1"
          sx={{ mb: 2 }}
        >
          Estamos constituidos por un grupo de profesionales excepcionales, quienes combinan una sólida formación en las bases técnicas de investigación con conocimientos y experiencia en el mundo de las compensaciones y de la gestión. Ofrecemos a nuestros clientes soluciones de gran valor agregado, permitiendo optimizar su gestión de Recursos Humanos.
        </Typography>

        <Typography variant="h5" sx={{ mb: 1, fontWeight: 'bold' }}>
          Hitos
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 3,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <ListItemText primary="Más de 300 Empresas en nuestros estudios" />
          </ListItem>
          <ListItem>
            <ListItemText primary="15 Años en el mercado local" />
          </ListItem>
          <ListItem>
            <ListItemText primary="21 Años de Historia" />
          </ListItem>
        </List>

    </Container>
  );
}

export default Nosotros;


/*

function Nosotros() {
  return (
    <Container sx={{ padding: '20px', lineHeight: '1.6' }}>
      <Box sx={{ p: 4, lineHeight: 1.6 }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '2rem',
            mb: 3,
            textAlign: 'center',
          }}
        >
          Nosotros
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2 }}
        >
          Somos una firma líder en investigación y desarrollo de estudios de compensaciones. Nos destacamos por el rigor que asignamos a dichas tareas, con una alta y exigente precisión técnica, pero a su vez, flexible para su análisis e interpretación.
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2 }}
        >
          Somos una empresa líder en investigación y elaboración de estudios de compensaciones en el Perú. Nos destacamos por el rigor que asignamos a dichas tareas, con un alto conocimiento de la realidad local. Nuestros servicios han sido contratados por las más prestigiosas empresas de la región, nacionales e internacionales, por organismos públicos y empresas del Estado.
        </Typography>
        <Typography
          variant="body1"
          sx={{ mb: 2 }}
        >
          Estamos constituidos por un grupo de profesionales excepcionales, quienes combinan una sólida formación en las bases técnicas de investigación con conocimientos y experiencia en el mundo de las compensaciones y de la gestión. Ofrecemos a nuestros clientes soluciones de gran valor agregado, permitiendo optimizar su gestión de Recursos Humanos.
        </Typography>

        <Typography
          variant="h5"
        >
          Hitos
        </Typography>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 3,
            '& .MuiListItem-root': {
              display: 'list-item',
            },
          }}
        >
          <ListItem>
            <ListItemText primary="Más de 300 Empresas en nuestros estudios" />
          </ListItem>
          <ListItem>
            <ListItemText primary="15 Años en el mercado local" />
          </ListItem>
          <ListItem>
            <ListItemText primary="21 Años de Historia" />
          </ListItem>
        </List>
      </Box>
    </Container>
  );
}

export default Nosotros;

*/